<style lang="scss" scoped>
.pop {
	max-height: 60vh;
	display: flex;
	flex-direction: column;
	.tool_bar {
		flex-shrink: 0;
	}
	.cell_content {
		flex: 1;
		overflow-y: auto;
		min-height: 100px;
	}
}
.group_title {
	margin-top: 0.2rem;
}
.success {
	color: $success;
}
</style>

<template>
	<div class="m_picker">
		<van-popup v-model="showPop" class="pop" position="bottom">
			<div class="van-picker__toolbar tool_bar">
				<button type="button" @click="cancel" class="van-picker__cancel">取消</button>
				<div class="van-ellipsis van-picker__title" v-text="title"></div>
				<button type="button" @click="confirm" class="van-picker__confirm">确认</button>
			</div>
			<div class="cell_content">
				<!-- 有分组 -->
				<template v-if="hasGroup">
					<van-cell-group v-for="(group, index) in list" :key="index">
						<div slot="title" class="group_title" v-text="group.label"></div>
						<van-cell v-for="(item, i) in group.options" clickable :key="i" :value="item[valueKey]" @click="check(item)">
							<template #right-icon>
								<van-icon v-if="item.mp_isChecked" name="success" class="success" />
							</template>
						</van-cell>
					</van-cell-group>
				</template>

				<!-- 无分组 -->
				<template v-else>
					<van-cell v-for="(item, index) in list" :key="index" :value="item[valueKey]" clickable @click="check(item)">
						<template #right-icon>
							<van-icon v-if="item.mp_isChecked" name="success" class="success" />
						</template>
					</van-cell>
				</template>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { Field, Popup, Picker, Cell, CellGroup, Button, Icon } from 'vant';

export default {
	name: 'mPicker', // vant picker多选组件
	components: {
		[Field.name]: Field,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Button.name]: Button,
		[Icon.name]: Icon,
	},
	model: {
		prop: 'show',
	},
	props: {
		show: {
			type: [Boolean, Number, String],
			require: true,
		},

		// 标题
		title: {
			type: String,
			required: false,
			default: '',
		},

		// 可选择的列
		columns: {
			type: Array,
			require: false,
			default: function () {
				/**
				 * 可以有分组，也可以无分组
				 * 无分组格式
				 * [{label: 'a', value: 1}, {label: 'b', value: 2}]
				 *
				 * 有分组格式：
				 * [{label: '分组一', options: [{label: 'a', value: 1}, {label: 'b', value: 2}]}]
				 */
				return [];
			},
		},

		// 可以定义值字段
		valueKey: {
			type: String,
			require: false,
			default: 'value',
		},
	},
	data() {
		return {
			isString: true,
			lastList: undefined, // 打开弹窗时候的数据
			list: [], // dom使用的数据
		};
	},
	computed: {
		showPop: {
			get() {
				return this.show;
			},
			set(val) {
				this.$emit('input', val);
			},
		},

		// 是否有分组
		hasGroup() {
			if (this.columns.length) {
				return Object.hasOwnProperty.call(this.columns[0], 'options');
			}
			return false;
		},
	},
	watch: {
		// 数据格式化
		columns: {
			handler: function (columns) {
				let list = JSON.parse(JSON.stringify(columns));
				let newList = list.map(item => {
					if (typeof item === 'object') {
						this.isString = false;
						if (Object.hasOwnProperty.call(item, 'options')) {
							let newOptions = item.options.map(op => {
								if (typeof op === 'object') {
									op.mp_isChecked = false;
									return op;
								} else {
									this.isString = true;
									return { value: op, mp_isChecked: false };
								}
							});
							item.options = newOptions;
						} else {
							item.mp_isChecked = false;
						}
						return item;
					} else {
						this.isString = true;
						return { value: item, mp_isChecked: false };
					}
				});

				this.list = newList;
				this.lastList = JSON.stringify(newList);
			},
			deep: true,
			immediate: true,
		},

		showPop: function (v) {
			if (v) {
				// 弹窗打开了
				this.lastList = JSON.stringify(this.list);
			}
		},
	},
	methods: {
		confirm() {
			let allOptions = [];
			this.list.forEach(item => {
				if (Object.hasOwnProperty.call(item, 'options')) {
					allOptions = [...allOptions, ...item.options];
				} else {
					allOptions.push(item);
				}
			});
			let checkedList = allOptions.filter(item => item.mp_isChecked);
			let result = JSON.parse(JSON.stringify(checkedList)).map(item => {
				if (this.isString) {
					return item.value;
				} else {
					delete item.mp_isChecked;
					return item;
				}
			});
			this.$emit('confirm', result);
		},

		cancel() {
			// 恢复打开时的状态
			this.list = JSON.parse(this.lastList);
			this.$emit('cancel');
		},

		check(dict) {
			dict.mp_isChecked = !dict.mp_isChecked;
			this.$forceUpdate();
		},
	},
};
</script>
